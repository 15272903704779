/* Modern map container */
.modern-map {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  overflow: hidden;
  /* Enable hardware acceleration */
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
}

.leaflet-pan-animated {
  transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  will-change: transform;
  transform-origin: center center;
}

/* Smoother tile loading */
.leaflet-tile-loaded {
  animation: tilesFadeIn 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: opacity;
}
/* Improved marker transitions */
.leaflet-marker-icon {
  transition: transform 0.2s ease-out;
}

/* Tile fade-in animation */
@keyframes tilesFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Pulsing location marker */
.pulsing-marker {
  width: 20px;
  height: 20px;
  background: rgba(38, 166, 154, 0.6);
  border-radius: 50%;
  position: relative;
  animation: pulse 1.5s infinite;
}

/* Smooth zoom animation
.leaflet-zoom-animated {
  transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
} */

.modern-controls {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1000;
  background: white;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

/* Loading animation */
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(38, 166, 154, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(38, 166, 154, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(38, 166, 154, 0);
  }
}
